import React from "react";
import { useParams } from "react-router-dom";
import PageLayout from "../../../../../../components/Commons/PageLayout";
import Giscus from "@giscus/react";
import { Avatar, Button, Card, Space, Typography } from "antd";
import { useOnChainData } from "../../../../../../../common/hooks/use-onchain-data";
import { useApp } from "../../../../../../../common/hooks/use-app";
import { fetchTokenInfo } from "../../../../../../../common/routers/global";
import {
  getChainIdByChain,
  getChainKeyByChainName,
  getChainNameByChain,
  getExplorer,
} from "../../../../../../../common/helpers";
import TokenStats from "../../../../../Analytics/components/Pairs/TokenDetailPage/TokenStats";
import CandleChart from "../../../../../Analytics/components/Pairs/TokenDetailPage/CandleChart";
import AddTokenButton from "../../../../../../components/AddTokenOnMetaMaskButton";

const SmithTokenInfo = () => {
  const params = useParams();
  let { chain, contractHash } = params as any;
  const { network, refreshCount } = useApp();
  chain = getChainKeyByChainName(chain);
  const { isLoaded, error, data } = useOnChainData(() => {
    return fetchTokenInfo(chain, network, contractHash);
  }, [network, refreshCount]);

  if (!isLoaded) return <div></div>;
  if (error) return <div>{error}</div>;

  return (
    <PageLayout>
      <div className="columns is-centered">
        <div className="column is-8">
          <Card>
            <Space>
              <Avatar src={data.icon} />
              <Space direction="vertical" size="small">
                <Typography.Title className="is-marginless is-accent" level={5}>
                  {data.symbol}
                </Typography.Title>
                <TokenStats chain={chain} tokenId={contractHash} />
                <Space>
                  <Button
                    size="small"
                    href={`${getExplorer(
                      chain,
                      network,
                      "contract"
                    )}/${contractHash}`}
                  >
                    View in block explorer
                  </Button>
                  <AddTokenButton
                    address={contractHash}
                    symbol={data.symbol}
                    decimals={data.decimals}
                    image={data.icon}
                    chainId={getChainIdByChain(chain as any, network)}
                    chainName={getChainNameByChain(chain as any)}
                  />
                </Space>
              </Space>
            </Space>
          </Card>

          <Card>
            <CandleChart chain={chain} tokenHash={contractHash} />
          </Card>

          <Card>
            <Giscus
              id="comments"
              repo="ForTheWinn/giscus"
              repoId="R_kgDOMkHLjg"
              category="Announcements"
              categoryId="DIC_kwDOMkHLjs4Chs60"
              mapping="specific"
              term={contractHash}
              reactionsEnabled="0"
              emitMetadata="0"
              inputPosition="bottom"
              theme="light"
              lang="en"
              loading="lazy"
            />
          </Card>
        </div>
      </div>
    </PageLayout>
  );
};

export default SmithTokenInfo;
